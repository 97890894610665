<template>
  <div>
    <digital-record-filters
      :search-params.sync="searchParams"
      @refetch-data="refetchData"
    />
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('Lists.Show') }}</label>
            <v-select
              id="per-page"
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block"
            />
            <label>{{ $t('Lists.Entries') }}</label>
          </b-col>
          <!--Actions -->
          <b-col
            md="3"
            offset-md="3"
          >
            <b-dropdown
              class="d-flex align-items-center justify-content-end"
              variant="outline-primary"
              block
              menu-class="w-100"
            >
              <template #button-content>
                <feather-icon
                  icon="CommandIcon"
                  size="16"
                  class="align-middle"
                />
                {{ $t('Components.ListHeader.Actions') }}
              </template>
              <b-dropdown-item @click="changeStateTime">
                <feather-icon icon="CalendarIcon" size="16" />
                <span class="align-middle ml-50">{{ stateTime.value ? $t('Components.List.Filters.DateWhitoutHour') : $t('Components.List.Filters.FormatDate') }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </b-col>

          <!-- New Digital Record -->
          <b-col
            md="2"
            cols="12"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                v-if="$can('create','ExpedientesDigitales')"
                variant="primary"
                :to="{ name: 'apps-digtalrecord-management-addedit'}"
              >
                <span>{{ $t('AppDigitalRecord.list.newExpedient') }} </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <!--Table-->
      <b-table
        ref="refDigitalRecordListTable"
        class="position-relative"
        responsive
        :fields="tableColumns"
        :items="fetchDigitalRecords"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(fechaPago)="data">
          <b-media v-if="stateTime.value" vertical-align="center">
            {{ data.item.fechaPago | formatDateTime }}
          </b-media>
          <b-media v-else vertical-align="center">
            {{ data.item.fechaPago | formatDate }}
          </b-media>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item :to="{ name: 'apps-digtalrecord-management-addedit', params: { idExpedienteDigital: data.item.idExpedienteDigital } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ $t('Lists.Edit') }}</span>
            </b-dropdown-item>
            <b-dropdown-item @click="onRemoveDigitalRecord(data.item.idExpedienteDigital)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">{{ $t('Lists.Delete') }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t('Lists.Showing') }} {{ dataMeta.from }} {{ $t('Lists.To') }} {{ dataMeta.to }} {{ $t('Lists.Of') }} {{ dataMeta.of }} {{ $t('Lists.Entries') }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalDigitalRecords"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BRow,
  BCol,
  BButton,
  BTable,
  BPagination,
  BSpinner,
  BModal,
  BFormGroup,
  BDropdown,
  BDropdownItem,
  BMedia,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import digitalRecordService from '@/services/digitalrecord.service'
import { defineComponent, ref } from '@vue/composition-api'
import DigitalRecordFilters from '../DigitalRecordFilters.vue'
import useDigitalRecordQuery from '../useDigitalRecordQuery'

export default defineComponent({
  components: {
    BCard,
    BCardHeader,
    BRow,
    BCol,
    BButton,
    BTable,
    BPagination,
    BSpinner,
    BModal,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BMedia,

    vSelect,

    DigitalRecordFilters,
  },
  setup() {
    /* Services */
    const { deleteOperation } = digitalRecordService()
    const dateTimeFormater = JSON.parse(localStorage.getItem('stateTime'))
    if (!dateTimeFormater.find(e => e.key === 'formatDateManagement')) {
      dateTimeFormater.push({ key: 'formatDateManagement', value: false })
      localStorage.setItem('stateTime', JSON.stringify(dateTimeFormater))
    }
    const stateTime = ref(dateTimeFormater.find(e => e.key === 'formatDateManagement'))

    const changeStateTime = () => {
      stateTime.value.value = !stateTime.value.value
      localStorage.setItem('stateTime', JSON.stringify(dateTimeFormater))
    }
    const {
      isSausage,
      refetchData,
      sortDigitalRecordIdAsc,
      fetchDigitalRecords,
      tableColumns,
      perPage,
      currentPage,
      totalDigitalRecords,
      dataMeta,
      perPageOptions,
      searchParams,
      sortBy,
      isSortDirDesc,
      refDigitalRecordListTable,

      refModalRequest,
    } = useDigitalRecordQuery()
    // Data
    isSausage.value = false
    // Events
    const onRemoveDigitalRecord = idExpedienteDigital => {
      deleteOperation(idExpedienteDigital)
        .then(() => {
          refetchData()
        })
    }

    return {
      refetchData,
      sortDigitalRecordIdAsc,
      fetchDigitalRecords,
      tableColumns,
      perPage,
      currentPage,
      totalDigitalRecords,
      dataMeta,
      perPageOptions,
      searchParams,
      sortBy,
      isSortDirDesc,
      refDigitalRecordListTable,
      refModalRequest,
      changeStateTime,
      stateTime,

      // Methods
      onRemoveDigitalRecord,
    }
  },
})
</script>
<style lang="scss" scoped>

</style>
